<template>
  <div ref="chart" style="width: 100%; height: 100%"></div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    orientation: {
      type: String,
      default: "vertical",
    },
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    getMaxNodesOnLevel(root) {
      let maxNodesOnLevel = 0;
      let nodesOnLevel = new Map();

      root.each((node) => {
        let level = node.depth;
        nodesOnLevel.set(level, (nodesOnLevel.get(level) || 0) + 1);
      });

      for (let value of nodesOnLevel.values()) {
        if (value > maxNodesOnLevel) maxNodesOnLevel = value;
      }

      return maxNodesOnLevel;
    },
    renderChart() {
      const padding = 50;
      const width = this.$refs.chart.clientWidth;
      const height = this.$refs.chart.clientHeight;

      // Create the chart
      const svg = d3
        .select(this.$refs.chart)
        .append("svg")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `-${padding} -${padding} ${width + padding * 2} ${
            height + padding * 2
          }`
        )
        .style("flex", "1");

      const chartContent = svg.append("g");

      const zoomBehavior = d3.zoom()
        .scaleExtent([0.1, 3]) // Set the minimum and maximum zoom scale
        .on("zoom", (event) => {
          chartContent.attr("transform", event.transform);
        });

      svg.call(zoomBehavior);

      const layout =
        this.orientation === "horizontal"
          ? d3.tree().size([height - padding * 2, width - padding * 2])
          : d3.tree().size([width - padding * 2, height - padding * 3]);

      const root = d3.hierarchy(this.nodes[0], (d) => d.childrens);
      layout(root);

      const maxNodesOnLevel = this.getMaxNodesOnLevel(root);
      const renderVertically = this.orientation === "vertical" && maxNodesOnLevel > 2;


      const links = root.links();
      const linkPath =
        this.orientation === "horizontal"
          ? d3
              .linkHorizontal()
              .x((d) => d.y + padding)
              .y((d) => d.x + padding)
          : d3
              .linkVertical()
              .x((d) => d.x + padding)
              .y((d) => d.y + padding);

      chartContent
        .append("defs")
        .append("marker")
        .attr("id", "arrowhead")
        .attr("viewBox", "-0 -5 10 10")
        .attr("refX", 5)
        .attr("refY", 0)
        .attr("orient", "auto")
        .attr("markerWidth", 8)
        .attr("markerHeight", 8)
        .attr("xoverflow", "visible")
        .append("svg:path")
        .attr("d", "M 0,-5 L 10,0 L 0,5")
        .attr("fill", "#ccc")
        .style("stroke", "none");

      chartContent
        .selectAll(".link")
        .data(links)
        .enter()
        .append("path")
        .attr("class", "link")
        .attr("d", linkPath)
        .attr("marker-end", "url(#arrowhead)");

      const nodes = root.descendants();

      const nodeGroup = chartContent
        .selectAll(".node")
        .data(nodes)
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", (d) =>
          this.orientation === "horizontal"
            ? `translate(${d.y + padding}, ${d.x + padding})`
            : `translate(${d.x + padding}, ${d.y + padding})`
        );

      nodeGroup
        .append("circle")
        .attr("r", (d) => d.data.size || 14)
        .attr("fill", (d) => d.data.color || "white")
        .attr("stroke", "black")
        .attr("stroke-width", 2)
        .on("click", (event, d) => {
          this.$emit("node-click", d.data);
        });

      nodeGroup
        .append("text")
        .attr("x", (d) => (renderVertically ? 0 : (d.data.size || 14) + 5))
        .attr("y", (d) => (renderVertically ? (d.data.size || 14) + 5 : -(d.data.size || 14) / 2))
        .attr("text-anchor", renderVertically ? "middle" : "start")
        .text((d) => d.data.name)
        .attr("fill", "black")
        .attr("font-size", (d) => d.data.fontSize || "14px")
        .attr("alignment-baseline", "middle");

      nodeGroup
        .append("text")
        .attr("x", (d) => (renderVertically ? 0 : (d.data.size || 14) + 5))
        .attr("y", (d) => (renderVertically ? (d.data.size || 14) + 20 : (d.data.size || 14) / 2 + 12))
        .attr("text-anchor", renderVertically ? "middle" : "start")
        .text((d) => d.data.description || "")
        .attr("fill", "black")
        .attr("font-size", (d) => d.data.descriptionFontSize || "12px")
        .attr("alignment-baseline", "middle");
    },
  },
};
</script>

<style>
.node {
  cursor: pointer;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}
</style>

